import React from "react"
import BasicLayout from "src/components/layout/BasicLayout/BasicLayout"
import SEO from "src/components/seo"
import DevelopmentPage from "src/components/DevelopmentPage/DevelopmentPage"

const DedicatedDevelopers = () => (
  <BasicLayout stickyBtn={false}>
    <SEO title="Dedicated Developers" />
    <DevelopmentPage
      pageTitle="Dedicated Developers"
      imagePath="dedicates-developers-intro.png"
    />
  </BasicLayout>
)

export default DedicatedDevelopers
